import { mapState, mapActions } from 'vuex';
import {
    UPDATE_LIST, CHANGE_PAGE, CHANGE_SIZE, FILTER_SCHEMA, FILTER_STATE, FILTER_SEARCH, FILTER_ORDER,
} from '@/store/tasks/actions';

import AppLoading from '@/components/common/AppLoading';
import AppNotFound from '@/components/common/AppNotFound';
import FileUpload from '@/components/common/FileUpload';
import TablePageSize from '@/components/common/TablePageSize';
import TablePagination from '@/components/common/TablePagination';
import SearchBox from '@/components/common/SearchBox';

import TaskItem from './TaskItem';

export default {
    name: 'TaskList',
    components: {
        AppLoading, AppNotFound, TaskItem, FileUpload, TablePageSize, TablePagination, SearchBox,
    },

    data() {
        return {
            isLoading: false,
            filterSchema: null,
            filterState: null,
            filterSearch: null,
            filterOrder: null,
            errorMsg: null,
        };
    },
    created() {
        this.reload();
    },

    watch: {
        tasksFilters(filters) {
            this.filterSchema = filters.schema || null;
            this.filterState = filters.state || null;
            this.filterSearch = filters.search || null;
            this.filterOrder = filters.orderBy || null;
        },
    },

    computed: {
        ...mapState('tasks', {
            tasksList: 'list',
            tasksPages: 'pages',
            tasksFilters: 'filters',
            tasksAvailable: 'available',
        }),
        isEmpty() { return this.tasksList == null || this.tasksList.length === 0; },
        hasFilterSchema() {
            return this.tasksAvailable.schema && this.tasksAvailable.schema.length > 0;
        },
        hasFilterState() {
            return this.tasksAvailable.state && this.tasksAvailable.state.length > 0;
        },
    },

    methods: {
        ...mapActions('tasks', {
            fetchTaskList: UPDATE_LIST,
            changePage: CHANGE_PAGE,
            changeSize: CHANGE_SIZE,
            setFilterSchema: FILTER_SCHEMA,
            setFilterState: FILTER_STATE,
            setFilterSearch: FILTER_SEARCH,
            setFilterOrder: FILTER_ORDER,
        }),

        reload() {
            this.isLoading = true;
            this.fetchTaskList().finally(() => {
                this.isLoading = false;
            });
        },
    },
};
