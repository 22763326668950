<template>
    <form v-if="form" v-on:submit.prevent="submit"
        class="form text-left container my-2">

        <div class="form-group">
            <label class="form-label">Название</label>
            <input class="form-input" type="text" placeholder="Название" :disabled="disabled"
                @input="update"  v-model="form.name">
        </div>

        <div v-for="(value, idx) in form.values" :key="idx" class="form-group">
            <label class="form-label">{{value.name}}{{value.is_required ? '*' : ''}}</label>
            <div v-if="value.type == 'string'">
                <input class="form-input" type="text" :disabled="disabled"
                    @input="update" v-model="value.valueText">
            </div>
            <div v-else-if="value.type == 'text'">
                <textarea class="form-input" type="text" rows="3" :disabled="disabled"
                    @input="update" v-model="value.valueText" />
            </div>
            <div v-else-if="value.type == 'number'">
                <input class="form-input" type="number" step="any" :disabled="disabled"
                    @input="update" v-model="value.valueText">
            </div>
            <div v-else-if="value.type == 'enum'">
                <select class="form-select" v-model="value.valueID"
                    @change="update" :disabled="disabled">
                    <option :disabled="value.is_required"
                        :value="null">-- Не выбрано --</option>
                    <option v-for="item in value.enumList" :key="item.id"
                        :value="item.id">{{item.name}}</option>
                </select>
            </div>
            <div v-else class="has-error">
                <div class="form-input-hint">
                    Данный тип аттрибута не поддерживается
                </div>
            </div>
        </div>

        <slot :payload="payload" :isValid="errorMsg == null" :isChanged="isChanged" />
    </form>
</template>

<style lang="scss" scoped>
.form-input:disabled {
    opacity: 1;
    background-color: white;
}
.form-select:disabled {
    opacity: 1;
    background-color: white;
}
</style>

<script>

import { createForm, formInfo } from './registryForm';

export default {
    name: 'RegistryRowForm',
    props: ['registry', 'row', 'disabled'],

    data() {
        return {
            form: null,
            payload: null,
            errorMsg: null,
            isChanged: false,
        };
    },

    created() { this.reload(); },
    watch: {
        row() { this.reload(); },
        registry() { this.reload(); },
    },

    methods: {
        reload() {
            this.form = createForm(this.registry, this.row);
            this.update();
        },

        update() {
            const { isChanged, payload, formError } = formInfo(this.form, this.registry, this.row);
            this.isChanged = isChanged;
            this.payload = payload;
            this.errorMsg = formError;
        },

        submit() {
            if (this.isChanged && this.errorMsg == null) {
                this.$emit('submit', this.payload);
            }
        },
    },
};
</script>
