import { mapState, mapActions, mapGetters } from 'vuex';

import * as A from '@/store/scope/actions';

import ErrorMsg from '@/components/common/ErrorMsg';
import AppLoading from '@/components/common/AppLoading';
import UploadButton from '@/components/common/UploadButton';
import FileUpload from '@/components/common/FileUpload';
import FileUploadButton from '@/components/common/FileUploadButton';
import FileInfo from '@/components/common/FileInfo';
import RemoveConfirmModal from '@/components/common/RemoveConfirmModal';

import AppScopeRef from './AppScopeRef';
import AppAttrList from './AppAttrList';
import AppSubScopes from './scopes/AppSubScopes';

export default {
    name: 'AppScopeConfig',
    props: ['appID'],

    components: {
        AppLoading,
        ErrorMsg,
        AppSubScopes,
        UploadButton,
        AppScopeRef,
        AppAttrList,
        RemoveConfirmModal,
        FileUpload,
        FileUploadButton,
        FileInfo,
    },

    data() {
        return {
            isLoading: true,
            info: {},
            attrChanged: false,
            error: null,
            uploadErrorMsg: null,
            errorRequired: false,
        };
    },

    computed: {
        ...mapState('scope', { scope: 'scope', scopeError: 'error' }),
        ...mapGetters('profile', ['isDeveloper', 'scopeRoles']),
        changed() {
            return this.attrChanged
                || this.info.name !== this.scope.name
                || this.info.code !== this.scope.code;
        },
        hasFiles() { return this.scope.files.length > 0; },

        isRootScope() { return !this.scope.parent; },
    },

    watch: {
        appID() { this.reload(); },
        scope(scope) { this.updateInfo(scope); },
    },

    created() {
        this.reload();
    },

    methods: {
        ...mapActions('scope', {
            loadScope: A.LOAD_SCOPE,
            updateScope: A.UPDATE_SCOPE,
            removeScope: A.REMOVE_SCOPE,
            addScopeFile: A.ADD_FILE,
            removeScopeFile: A.REMOVE_FILE,
            importScopePack: A.IMPORT_SCOPE,
        }),

        updateInfo(scope) {
            if (scope) {
                this.info = {
                    id: scope.id,
                    code: scope.code,
                    name: scope.name,
                    type: scope.type,
                };
            }
        },

        reload() {
            this.isLoading = true;
            this.loadScope(this.appID).then(() => {
                this.isLoading = false;
            }).catch(() => {
                this.$router.push({ name: 'default' });
            });
        },

        clickRemoveScope(ev) {
            if (ev.ctrlKey) {
                this.$refs.removeForceScopeModal.open();
            } else if (this.scope.canRemove) {
                this.$refs.removeScopeModal.open();
            }
        },

        submitRemoveScope(force) {
            if (!this.scope.parent || !this.scope.parent.id) {
                this.$refs.removeForceScopeModal.hide();
                this.$refs.removeScopeModal.hide();
                this.error = null;
                this.errorRequired = false;
                return;
            }

            const parentID = this.scope.parent.id;
            this.removeScope({ appID: this.appID, force }).then(() => {
                this.$router.push({ name: 'app-config', params: { appID: parentID } });
            }).catch((err) => {
                this.$refs.removeForceScopeModal.hide();
                this.$refs.removeScopeModal.hide();
                this.error = err;
                this.errorRequired = false;
            });
        },

        attrListUpdate(changed) {
            this.attrChanged = changed;
        },

        submitUpdateScope() {
            if (this.info == null || this.info.name == null || this.info.name.length < 1) {
                this.error = 'Нужно ввести название раздела';
                this.errorRequired = false;
                this.$refs.uploadBtn.stop();
                return;
            }

            // Валидируем введеные значения
            this.errorRequired = !this.$refs.attrList.validate();
            if (this.errorRequired) {
                this.$refs.uploadBtn.stop();
                return;
            }

            const payload = {
                name: this.info.name,
                attrs: this.$refs.attrList.payload(),
            };

            this.updateScope({ appID: this.appID, payload }).then(() => {
                this.$refs.uploadBtn.stop();
                this.$refs.attrList.reload();
                this.error = null;
            }).catch((err) => {
                this.$refs.uploadBtn.stop();
                this.error = err;
                this.errorRequired = false;
            });
        },

        submitAddFile(payload) {
            this.addScopeFile(payload).then(() => {
                this.uploadErrorMsg = null;
            }).catch((err) => {
                this.uploadErrorMsg = err.message;
            });
        },

        submitRemoveFile(fileID) {
            this.removeScopeFile(fileID);
        },

        submitImport(pack) {
            this.importScopePack(pack);
        },
    },
};
