import { mapState, mapActions } from 'vuex';

import { LOAD_VALUE } from '@/store/taskvalue/actions';

import AppLoading from '@/components/common/AppLoading';
import AppNotFound from '@/components/common/AppNotFound';
import FileInfo from '@/components/common/FileInfo';

import Api from '@/api';

export default {
    name: 'TaskValue',
    props: ['tvID'],
    components: {
        AppLoading, FileInfo, AppNotFound,
    },

    data() {
        return {
            isLoading: false,
            isNotFound: false,
            errorMsg: null,
        };
    },

    computed: {
        ...mapState('taskvalue', {
            tvInfo: 'taskvalue',
        }),
    },

    watch: {
        tvID() { this.reload(); },
    },
    created() { this.reload(); },

    methods: {
        ...mapActions('taskvalue', {
            fetchInfo: LOAD_VALUE,
        }),

        taskValueFileURL(tv, file) {
            return Api.taskValueFileURL(tv.id, file.id);
        },

        reload() {
            this.isLoading = true;
            this.isNotFound = false;
            this.fetchInfo(this.tvID).then(() => {
                this.isLoading = false;
                this.errorMsg = null;
            }).catch((err) => {
                this.isLoading = false;
                this.isNotFound = true;
                this.errorMsg = err.message;
            });
        },
    },
};
