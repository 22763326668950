import { mapActions, mapState } from 'vuex';
import { SELECT_TASK, COMPLETE_TASK } from '@/store/tasks/actions';

import Api from '@/api';

import AppLoading from '@/components/common/AppLoading';

import TaskForm from './TaskForm.vue';

export default {
    name: 'TaskInfo',
    props: ['taskID'],
    components: { AppLoading, TaskForm },

    data() {
        return {
            isLoading: false,
            errorMsg: null,
            action: null,
            force: false,
        };
    },

    computed: {
        ...mapState('tasks', {
            taskInfo: 'selected',
        }),
    },

    watch: {
        taskID() { this.reload(); },
    },
    created() { this.reload(); },

    updated() {
        if (this.$refs.taskForm) {
            this.$refs.taskForm.openForm(this.action);
        }
    },

    methods: {
        ...mapActions('tasks', {
            fetchTask: SELECT_TASK,
            completeTask: COMPLETE_TASK,
        }),

        reload() {
            this.isLoading = true;
            this.fetchTask(this.taskID).then(() => {
                this.isLoading = false;
                this.errorMsg = null;
                this.action = null;
                this.force = this.taskInfo.processes == null
                    || this.taskInfo.processes.active === 0;
            }).catch(() => {
                this.$router.push({ name: 'tasks' });
            });
        },

        listUsers(opts) {
            return Api.listTaskExecutors(this.taskID, this.action.actionID, opts);
        },

        listScopes(opts) {
            return Api.listTaskScopes(this.taskID, this.action.actionID, opts);
        },

        listReports(stateID, opts) {
            return Api.listTaskReports(this.taskID, stateID, opts);
        },
        taskLinkUrl(link) {
            return Api.taskLinkURL(this.taskID, link.id);
        },

        selectAction(act) {
            this.action = act;
            this.$refs.taskForm.openForm(act);
            // Небольшой хак для простых действий - пропускаем подтвреждение
            if (this.action.type === 'simple' && this.action.duration == null && this.action.is_finish !== true) {
                this.$refs.taskForm.complete();
            }
        },

        submitComplete(payload) {
            this.completeTask({
                taskID: this.taskID,
                actionID: this.action.actionID,
                payload,
            }).then((resp) => {
                if (resp.data && resp.data.next_id) {
                    this.$router.replace({ name: 'task-info', params: { taskID: resp.data.next_id } });
                } else {
                    this.$router.replace({ name: 'tasks' });
                }
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },
    },
};
