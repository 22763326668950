import { mapState, mapActions } from 'vuex';
import { UPDATE_INFO, UPDATE_AVATAR, UPDATE_PASSWORD } from '@/store/profile/actions';

import UploadButton from '@/components/common/UploadButton';

import UserProfileForm from './UserProfileForm';
import UserAvatarForm from './UserAvatarForm';
import UpdatePasswordModal from './UpdatePasswordModal';

export default {
    name: 'UserProfile',

    components: {
        UserProfileForm, UserAvatarForm, UploadButton, UpdatePasswordModal,
    },

    data() {
        return {
            profileChanged: false,
            avatarChanged: false,
        };
    },

    computed: {
        ...mapState('profile', { profile: 'profile', profileTouch: 'lastTouch' }),
    },

    methods: {
        ...mapActions('profile', {
            updateAvatar: UPDATE_AVATAR,
            updateInfo: UPDATE_INFO,
            updatePassword: UPDATE_PASSWORD,
        }),

        uploadAll() {
            // Так как обновление профиля и обновление аватара - разные операции, то
            // мы запускаем их парралелельно (если они были измененны) - и после окончания
            // обеих операций завершаем анимацию кнопки аплоада
            const updateProfile = new Promise((resolve, reject) => {
                if (this.profileChanged) {
                    this.updateInfo(this.$refs.userProfile.payload).then(resolve, reject);
                } else {
                    resolve();
                }
            });
            const updateAvatar = new Promise((resolve, reject) => {
                if (this.avatarChanged) {
                    this.updateAvatar(this.$refs.userAvatar.avatarData).then(() => {
                        this.$refs.userAvatar.reset();
                        resolve();
                    }).catch(reject);
                } else {
                    resolve();
                }
            });

            Promise.all([updateProfile, updateAvatar]).finally(() => {
                this.$refs.uploadBtn.stop();
                this.$refs.userProfile.reload();
                this.profileChanged = this.$refs.userProfile.changed;
            });
        },

        uploadPassword(payload) {
            this.updatePassword(payload).then(() => {
                this.$refs.passwordModal.hide();
            }).catch((err) => {
                this.$refs.passwordModal.showError(err.message);
            });
        },
    },
};
