<template>
    <div class="tile tile-centered">
        <div class="tile-icon mx-2">
            <div class="avatar-icon">
                <img class="avatar centered" :src="user | processAvatar(processID, lastTouch)" />
            </div>
        </div>
        <div class="tile-content">
            <p class="tile-title">{{user | userFullName}}</p>
            <p class="tile-subtitle text-gray">{{user.username}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AssignedUserRef',
    props: ['user', 'processID', 'lastTouch'],
};
</script>
