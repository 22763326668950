<template>
    <div>
        <error-msg :error="rowsError" />
        <app-loading v-if="isLoading" />
        <div v-else-if="!rowsError" class="panel col-12">
            <div class="panel-header">
                <div class="panel-title h3">{{registry.name}}</div>
                <div class="text-italic text-left">{{registry.description}}</div>
            </div>

           <div class="panel-nav form-group text-right">
                <div class="column">
                    <search-box v-model="filterSearch" @change="setFilterSearch(filterSearch)" />
                </div>
           </div>

           <div class="panel-body columns">
                <app-not-found class="col-mx-auto" v-if="isEmpty">Данные не найдены</app-not-found>
                <table v-else class="table table-striped table-hover">
                    <thead>
                        <tr class="text-bold">
                            <td>Название</td>
                            <td v-for="column in registry.columns" :key="column.id"
                                >{{column.name}}</td>
                       </tr>
                    </thead>
                    <tbody>
                        <router-link custom v-slot="{ navigate }"
                            :key="item.id" v-for="item in rowsList"
                            :to="{name: 'app-registry-row', params: { appID, rowID: item.id }}">
                            <row-line @click.native="navigate"
                                :row="item" :columns="registry.columns" />
                        </router-link>
                    </tbody>
                    <table-pagination
                        :page="rowsPage.page" :last="rowsPage.count"
                        @changed="changePage($event)"/>
                </table>
           </div>

           <div class="panel-footer columns">
                <div v-if="scopeRoles.isRegistriesManager" class="text-left col-mr-auto">
                    <router-link custom v-slot="{ navigate }"
                        :to="{name: 'app-registry-new-row', params: { appID, registryID }}">
                        <button type="button" class="btn btn-primary tooltip-bottom"
                            @click="navigate">Добавить</button>
                    </router-link>
                </div>
                <div class="column">
                    <table-page-size class="col-ml-auto" @changed="changeSize($event)"
                        :size="rowsPage.size" :total="rowsPage.total"
                    >строк</table-page-size>
                </div>
           </div>
        </div>
    </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex';
import * as A from '@/store/scope/registries/actions';

import ErrorMsg from '@/components/common/ErrorMsg';
import AppLoading from '@/components/common/AppLoading';
import AppNotFound from '@/components/common/AppNotFound';
import TablePageSize from '@/components/common/TablePageSize';
import TablePagination from '@/components/common/TablePagination';
import SearchBox from '@/components/common/SearchBox';

import RowLine from './RowLine';

export default {
    name: 'ScopeRegistryRows',
    props: ['appID', 'registryID'],
    components: {
        AppLoading, RowLine, ErrorMsg, AppNotFound, TablePageSize, TablePagination, SearchBox,
    },

    data() {
        return {
            isLoading: false,
            filterSearch: null,
        };
    },

    created() {
        this.reloadList();
    },

    watch: {
        appID: 'reloadList',
        registryID: 'reloadList',
        registriesFilters: 'reloadFilters',
    },

    computed: {
        ...mapGetters('profile', ['scopeRoles']),
        ...mapState('scope/registryrows', {
            registry: 'registry',
            rowsList: 'list',
            rowsPage: 'pages',
            rowsFilters: 'filters',
            rowsAvailable: 'available',
            rowsError: 'error',
        }),
        isEmpty() { return this.rowsList == null || this.rowsList.length === 0; },
    },

    methods: {
        ...mapActions('scope/registryrows', {
            loadRowsList: A.UPDATE_LIST,
            changePage: A.CHANGE_PAGE,
            changeSize: A.CHANGE_SIZE,
            setFilterSearch: A.FILTER_SEARCH,
        }),

        reloadFilters(filters) {
            this.filterSearch = filters.search || null;
        },

        reloadList() {
            this.isLoading = true;
            this.loadRowsList({ appID: this.appID, registryID: this.registryID }).finally(() => {
                this.reloadFilters(this.rowsFilters);
                this.isLoading = false;
            });
        },
    },
};
</script>
