import { mapState, mapActions } from 'vuex';

import { SELECT_REPORT } from '@/store/reports/actions';

import AppLoading from '@/components/common/AppLoading';
import TaskValue from '@/components/tasks/TaskValue';

export default {
    name: 'ReportInfo',
    props: ['reportID'],
    components: { AppLoading, TaskValue },

    data() {
        return {
            isLoading: false,
        };
    },

    computed: {
        ...mapState('reports', {
            reportInfo: 'selected',
        }),
    },

    watch: {
        reportID() { this.reload(); },
    },
    created() { this.reload(); },

    methods: {
        ...mapActions('reports', {
            fetchReport: SELECT_REPORT,
        }),

        reload() {
            this.isLoading = true;
            this.fetchReport(this.reportID).then(() => {
                this.isLoading = false;
            }).catch(() => {
                this.$router.push({ name: 'reports' });
            });
        },
    },
};
