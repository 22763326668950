import { render, staticRenderFns } from "./NewScope.vue?vue&type=template&id=d887abce&"
import script from "./NewScope.js?vue&type=script&lang=js&"
export * from "./NewScope.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports