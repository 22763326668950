import { mapState, mapActions, mapGetters } from 'vuex';

import { SELECT_REPORT } from '@/store/scope/reports/actions';

import Api from '@/api';

import AppLoading from '@/components/common/AppLoading';
import TaskValue from '@/components/tasks/TaskValue';

export default {
    name: 'AppReportInfo',
    props: ['appID', 'reportID'],
    components: { AppLoading, TaskValue },

    data() {
        return {
            isLoading: false,
            errorMsg: null,
            importMsg: null,
        };
    },

    computed: {
        ...mapState('scope/reports', {
            reportInfo: 'selected',
        }),
        ...mapGetters('profile', ['isSuperAdmin']),
        reportXlsxURL() { return Api.processReportURL(this.reportInfo.pid); },
        report2XlsxURL() { return Api.process2ReportURL(this.reportInfo.pid); },
        report3XlsxURL() { return Api.process3ReportURL(this.reportInfo.pid); },
        reportDumpURL() { return Api.processDumpURL(this.reportInfo.pid); },
    },

    watch: {
        appID() { this.reload(); },
        reportID() { this.reload(); },

        processState(state) {
            if (state != null) {
                this.$refs.taskForm.openForm(state);
            } else {
                this.$refs.taskForm.close();
            }
        },
    },

    created() { this.reload(); },

    methods: {
        ...mapActions('scope/reports', {
            fetchInfo: SELECT_REPORT,
        }),

        reload() {
            this.isLoading = true;
            this.fetchInfo(this.reportID).then(() => {
                this.isLoading = false;
                this.errorMsg = null;
            }).catch(() => {
                this.$router.push({ name: 'app-reports', params: { appID: this.appID } });
            });
        },

        importDump(file) {
            const payload = new FormData();
            payload.append('file', file);
            Api.importProcessDump(this.reportInfo.pid, payload).then((resp) => {
                this.importMsg = resp.data;
                this.$refs.fileUpload.value = '';
            }).catch((err) => {
                this.importMsg = err.message;
                this.$refs.fileUpload.value = '';
            });
        },
    },
};
