import { mapState, mapActions } from 'vuex';
import {
    UPDATE_LIST, CHANGE_PAGE, CHANGE_SIZE, FILTER_FINISHED, FILTER_SCHEMA, FILTER_STATE,
} from '@/store/reports/actions';

import AppLoading from '@/components/common/AppLoading';
import AppNotFound from '@/components/common/AppNotFound';
import TablePageSize from '@/components/common/TablePageSize';
import TablePagination from '@/components/common/TablePagination';
import FileInfo from '@/components/common/FileInfo';

import TaskItem from '@/components/tasks/TaskItem';

export default {
    name: 'ReportsList',
    components: {
        AppLoading, AppNotFound, FileInfo, TaskItem, TablePageSize, TablePagination,
    },

    data() {
        return {
            isLoading: false,
            showFinished: false,
            filterSchema: null,
            filterState: null,
        };
    },
    created() {
        this.reloadList();
        this.reloadFilters(this.reportsFilters);
    },

    watch: {
        reportsFilters: 'reloadFilters',
    },

    computed: {
        ...mapState('reports', {
            reportsList: 'list',
            reportsPage: 'pages',
            reportsFilters: 'filters',
            reportsAvailable: 'available',
        }),
        isEmpty() { return this.reportsList == null || this.reportsList.length === 0; },
        hasFilterSchema() {
            return this.reportsAvailable.schema && this.reportsAvailable.schema.length > 0;
        },
        hasFilterState() {
            return this.reportsAvailable.state && this.reportsAvailable.state.length > 0;
        },
    },

    methods: {
        ...mapActions('reports', {
            fetchReportsList: UPDATE_LIST,
            changePage: CHANGE_PAGE,
            changeSize: CHANGE_SIZE,
            setFilterFinished: FILTER_FINISHED,
            setFilterSchema: FILTER_SCHEMA,
            setFilterState: FILTER_STATE,
        }),

        reloadFilters(filters) {
            this.showFinished = filters.all || false;
            this.filterSchema = filters.schema || null;
            this.filterState = filters.state || null;
        },

        reloadList() {
            this.isLoading = true;
            this.fetchReportsList().then(() => {
                this.isLoading = false;
            }).catch(() => {
                this.$router.push({ name: 'tasks' });
            });
        },
    },
};
