<template>
    <div>
        <error-msg :error="error" />
        <app-loading v-if="isLoading" />
        <div v-else class="panel col-10 col-sm-12">
            <div class="panel-header">
                <div class="panel-title h3">{{row.name}}</div>
                <router-link custom v-slot="{ navigate }"
                    :to="{name: 'app-registry-rows', params: { appID, registryID: registry.id }}">
                    <a class="panel-title h5 text-italic c-hand" @click="navigate"
                        >{{registry.name}}</a>
                </router-link>
            </div>
            <div class="panel-nav">
                <registry-row-form @submit="submitUpdate($event)"
                    :registry="registry" :row="row" :disabled="!scopeRoles.isRegistriesManager"
                    v-slot="form">
                    <div class="form-group columns" v-if="scopeRoles.isRegistriesManager">
                        <div class="column text-left">
                            <button class="btn btn-primary" type="button"
                                :disabled="!form.isChanged || !form.isValid"
                                @click="submitUpdate(form.payload)"
                                >Сохранить</button>
                        </div>
                        <div class="column col-auto text-right">
                            <button class="btn btn-error" type="button"
                                @click="$refs.removeModal.open()"
                                >Удалить</button>
                        </div>
                    </div>
                </registry-row-form>
            </div>
            <remove-confirm-modal ref="removeModal" @confirm-remove="submitRemove()">
                <div class="h5">Вы действительно хотите удалить запись
                    {{row.name}}?</div>
            </remove-confirm-modal>
        </div>
    </div>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex';
import { SELECT_ROW, UPDATE_ROW, REMOVE_ROW } from '@/store/scope/registryrows/actions';

import AppLoading from '@/components/common/AppLoading';
import ErrorMsg from '@/components/common/ErrorMsg';
import RemoveConfirmModal from '@/components/common/RemoveConfirmModal';

import RegistryRowForm from './RegistryRowForm';

export default {
    name: 'RegistryRowInfo',
    props: ['appID', 'rowID'],
    components: {
        AppLoading,
        ErrorMsg,
        RegistryRowForm,
        RemoveConfirmModal,
    },

    data() {
        return {
            isLoading: false,
        };
    },

    created() {
        this.reload();
    },

    watch: {
        appID: 'reload',
        rowID: 'reload',
    },

    computed: {
        ...mapGetters('profile', ['scopeRoles']),
        ...mapState('scope/registryrows', {
            registry: 'registry',
            row: 'row',
            error: 'error',
        }),
    },

    methods: {
        ...mapActions('scope/registryrows', {
            loadRowInfo: SELECT_ROW,
            updateRow: UPDATE_ROW,
            removeRow: REMOVE_ROW,
        }),

        reload() {
            this.isLoading = true;
            const { appID, rowID } = this;
            this.loadRowInfo({ appID, rowID }).finally(() => {
                this.isLoading = false;
            });
        },

        submitUpdate(payload) {
            const { appID, rowID } = this;
            const registryID = this.registry.id;
            this.updateRow({ appID, rowID, payload }).then(() => {
                this.$router.push({ name: 'app-registry-rows', params: { appID, registryID } });
            });
        },

        submitRemove() {
            const { appID, rowID } = this;
            const registryID = this.registry.id;
            this.removeRow({ appID, rowID }).then(() => {
                this.$router.push({ name: 'app-registry-rows', params: { appID, registryID } });
            });
        },
    },
};
</script>
