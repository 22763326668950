import { mapState, mapActions } from 'vuex';

import { SELECT_PROCESS } from '@/store/processes/actions';

import AppLoading from '@/components/common/AppLoading';
import FileInfo from '@/components/common/FileInfo';
import TaskHistory from '@/components/tasks/TaskHistory';

import AssignedUserRef from './AssignedUserRef';

export default {
    name: 'ProcessInfo',
    props: ['processID'],
    components: {
        AppLoading, FileInfo, TaskHistory, AssignedUserRef,
    },

    data() {
        return {
            isLoading: false,
            errorMsg: null,
        };
    },

    computed: {
        ...mapState('processes', {
            processInfo: 'selected',
            lastTouch: 'lastTouch',
        }),

        isAssigned() {
            return this.processInfo != null
                && this.processInfo.executors != null
                && this.processInfo.executors.length > 0;
        },
    },

    watch: {
        processID() { this.reload(); },
    },
    created() { this.reload(); },

    methods: {
        ...mapActions('processes', {
            fetchInfo: SELECT_PROCESS,
        }),

        reload() {
            this.isLoading = true;
            this.fetchInfo(this.processID).then(() => {
                this.isLoading = false;
                this.errorMsg = null;
            }).catch(() => {
                this.$router.push({ name: 'processes' });
            });
        },
    },
};
