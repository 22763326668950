<template>
    <div>
        <error-msg :error="error" />
        <app-loading v-if="isLoading" />
        <div v-else class="panel col-10 col-sm-12">
            <div class="panel-header">
                <div class="panel-title h3">Новое значение в реестре</div>
                <router-link custom v-slot="{ navigate }"
                    :to="{name: 'app-registry-rows', params: { appID, registryID: registry.id }}">
                    <a class="panel-title h5 text-italic c-hand" @click="navigate"
                        >{{registry.name}}</a>
                </router-link>
            </div>
            <div class="panel-nav">
                <registry-row-form @submit="submit($event)"
                    :registry="registry" :disabled="false"
                    v-slot="form">
                    <div class="form-group">
                        <button class="btn btn-primary" type="button"
                            :disabled="!form.isValid"
                            @click="submit(form.payload)"
                            >Создать</button>
                    </div>
                </registry-row-form>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import { UPDATE_LIST, CREATE_ROW } from '@/store/scope/registryrows/actions';

import AppLoading from '@/components/common/AppLoading';
import ErrorMsg from '@/components/common/ErrorMsg';

import RegistryRowForm from './RegistryRowForm';

export default {
    name: 'CreateNewRegistryRow',
    props: ['appID', 'registryID'],
    components: { AppLoading, ErrorMsg, RegistryRowForm },

    data() {
        return {
            isLoading: false,
        };
    },

    created() {
        this.reloadList();
    },

    watch: {
        appID: 'reloadList',
        registryID: 'reloadList',
    },

    computed: {
        ...mapState('scope/registryrows', {
            registry: 'registry',
            error: 'error',
        }),
    },

    methods: {
        ...mapActions('scope/registryrows', {
            loadRowsList: UPDATE_LIST,
            createRegistryRow: CREATE_ROW,
        }),

        reloadList() {
            this.isLoading = true;
            const { appID, registryID } = this;
            this.loadRowsList({ appID, registryID }).then(() => {
                this.isLoading = false;
            }).catch(() => {
                this.$router.push({ name: 'app-registry-rows', params: { appID, registryID } });
            });
        },

        submit(payload) {
            const { appID, registryID } = this;
            this.createRegistryRow({ typeID: this.typeID, payload }).then(() => {
                this.$router.push({ name: 'app-registry-rows', params: { appID, registryID } });
            });
        },
    },
};
</script>
