function valueValid(value) {
    if (value.type === 'enum') {
        return value.valueID != null;
    }
    return value.valueText && value.valueText.length > 0;
}

function isChanged(form, row) {
    if (row == null) {
        return true;
    }

    if (form.name !== row.name) {
        return true;
    }

    const values = form.values ? form.values.filter(valueValid) : [];

    if (values.length !== Object.keys(row.values).length) {
        return true;
    }

    for (let idx = 0; idx < values.length; idx += 1) {
        const fv = values[idx];
        const rv = row.values[fv.columnID];
        if (!rv) {
            return true;
        }

        if (rv.id !== fv.id
            || rv.text !== fv.valueText
            || rv.enumID !== fv.valueID) {
            return true;
        }
    }

    return false;
}

function validateForm(form, registry) {
    if (!form) {
        return 'Form is null';
    }
    if (!form.name || form.name.length === 0) {
        return 'Empty name is forbidden';
    }

    const valuesByColumns = {};
    for (let idx = 0; idx < form.values.length; idx += 1) {
        const value = form.values[idx];
        if (valueValid(value)) {
            if (valuesByColumns[value.columnID]) {
                return `Dublicate value with columnID ${value.columnID}`;
            }
            valuesByColumns[value.columnID] = value;
        }
    }

    for (let idx = 0; idx < registry.columns.length; idx += 1) {
        const column = registry.columns[idx];
        if (column.is_required && !valuesByColumns[column.id]) {
            return `Not found required value for column ${column.name}`;
        }
    }
    return null;
}

function valuePayload(value) {
    const payload = {
        columnID: value.columnID,
    };
    if (value.id) {
        payload.id = value.id;
    }
    if (value.type === 'enum') {
        payload.enumID = value.valueID;
    } else {
        payload.text = value.valueText;
    }
    return payload;
}

function formPayload(form) {
    return {
        id: form.id,
        name: form.name,
        values: form.values.filter(valueValid).map(valuePayload),
    };
}

function createValue(column, value) {
    return {
        id: (value) ? value.id : null,
        columnID: column.id,
        name: column.name,
        type: column.type,
        is_required: column.is_required,
        valueText: (value) ? value.text : null,
        valueID: (value) ? value.enumID : null,
        enumList: (column.type === 'enum') ? JSON.parse(column.options) : null,
    };
}

export function createForm(registry, row) {
    return {
        id: (row ? row.id : null),
        name: (row ? row.name : null),
        values: registry.columns.map(column => createValue(
            column, (row && row.values[column.id]) ? row.values[column.id] : null,
        )),
    };
}

export function formInfo(form, registry, row) {
    return {
        isChanged: isChanged(form, row),
        payload: formPayload(form),
        formError: validateForm(form, registry),
    };
}
