<template>
    <div class="file_upload">
        <div class="input text-center">
            <input class="file" ref="files" type="file" :accept="accept"
                @change="change($event.target.files[0])" />
            <button class="btn btn-primary" @click="open()" :disabled="disabled"><slot /></button>
        </div>
    </div>
</template>

<style>
input[type="file"]{
    position: absolute;
    top: -500px;
}
</style>

<script>
export default {
    name: 'FileUploadButton',
    props: {
        accept: {
            type: String,
            default: '*',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        open() {
            this.$refs.files.click();
        },

        change(file) {
            this.$emit('upload-file', file);
            this.$refs.files.value = '';
        },
    },
};
</script>
