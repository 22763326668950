<template>
    <div>
        <error-msg :error="registriesError" />
        <app-loading v-if="isLoading" />
        <div v-else-if="!registriesError" class="col-12">
            <div class="form-group columns text-right">
                <div class="column">
                    <search-box v-model="filterSearch" @change="setFilterSearch(filterSearch)" />
                </div>
            </div>

            <app-not-found v-if="isEmpty">Реестры не найдены</app-not-found>
            <div v-else class="text-left">
                <div :key="item.id" v-for="item in registriesList">
                    <router-link custom v-slot="{ navigate }"
                        :to="{ name: 'app-registry-rows', params: { appID, registryID: item.id }}" >
                        <div @click="navigate">
                            <registry-ref class="c-hover-hand" :registry="item" />
                        </div>
                    </router-link>
                </div>
                <table-pagination
                    :page="registriesPage.page" :last="registriesPage.count"
                    @changed="changePage($event)"/>
            </div>
            <div class="columns p-2">
                <table-page-size class="col-ml-auto" @changed="changeSize($event)"
                    :size="registriesPage.size" :total="registriesPage.total"
                >реестров</table-page-size>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import * as A from '@/store/scope/registries/actions';

import ErrorMsg from '@/components/common/ErrorMsg';
import AppLoading from '@/components/common/AppLoading';
import AppNotFound from '@/components/common/AppNotFound';
import TablePageSize from '@/components/common/TablePageSize';
import TablePagination from '@/components/common/TablePagination';
import SearchBox from '@/components/common/SearchBox';

import RegistryRef from '@/components/scope/types/registries/RegistryRef';

export default {
    name: 'AppRegistriesList',
    props: ['appID'],
    components: {
        AppLoading, RegistryRef, ErrorMsg, AppNotFound, TablePageSize, TablePagination, SearchBox,
    },

    data() {
        return {
            isLoading: false,
            filterSearch: null,
        };
    },

    created() {
        this.reloadList();
    },

    watch: {
        appID: 'reloadList',
        registriesFilters: 'reloadFilters',
    },

    computed: {
        ...mapState('scope/registries', {
            registriesList: 'list',
            registriesPage: 'pages',
            registriesFilters: 'filters',
            registriesAvailable: 'available',
            registriesError: 'error',
        }),
        isEmpty() { return this.registriesList == null || this.registriesList.length === 0; },
    },

    methods: {
        ...mapActions('scope/registries', {
            loadRegistriesList: A.UPDATE_LIST,
            changePage: A.CHANGE_PAGE,
            changeSize: A.CHANGE_SIZE,
            setFilterSearch: A.FILTER_SEARCH,
        }),

        reloadFilters(filters) {
            this.filterSearch = filters.search || null;
        },

        reloadList() {
            this.isLoading = true;
            this.loadRegistriesList({ appID: this.appID }).finally(() => {
                this.reloadFilters(this.registriesFilters);
                this.isLoading = false;
            });
        },
    },
};
</script>
