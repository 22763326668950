import { mapGetters, mapState, mapActions } from 'vuex';

import { CREATE_SCOPE } from '@/store/scope/actions';
import { UPDATE_LIST, SELECT_SCOPETYPE } from '@/store/scope/types/actions';

import AppLoading from '@/components/common/AppLoading';
import AppAttrList from './AppAttrList.vue';

export default {
    name: 'NewScope',
    props: ['appID'],

    components: { AppLoading, AppAttrList },

    data() {
        return {
            isLoading: false,
            name: null,
            typeID: null,
            errorMsg: null,
            errorRequired: false,
        };
    },

    watch: {
        appID: 'reload',
        typeID: 'reloadType',
    },

    computed: {
        ...mapGetters('profile', ['scopeRoles']),
        ...mapState('scope/types', { typeList: 'types', type: 'selected' }),

        canSubmit() {
            return this.type != null && this.name != null && this.name.length > 0;
        },
    },

    created() {
        this.reload();
    },

    methods: {
        ...mapActions('scope', {
            createScope: CREATE_SCOPE,
        }),
        ...mapActions('scope/types', {
            reloadTypes: UPDATE_LIST,
            selectType: SELECT_SCOPETYPE,
        }),

        reload() {
            this.isLoading = true;
            this.reloadTypes(this.appID).then(() => {
                this.isLoading = false;
                this.typeID = null;
                this.errorMsg = null;
                this.errorRequired = false;
            }).catch(() => {
                this.$router.push({ name: 'tasks' });
            });
        },

        reloadType() {
            if (this.typeID == null) {
                this.errorMsg = null;
                this.attrs = [];
                return;
            }
            this.selectType({
                scopeID: this.appID,
                typeID: this.typeID,
            }).then(() => {
                this.errorMsg = null;
                this.errorRequired = false;
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },

        submitCreate() {
            // Валидируем введеные значения
            this.errorRequired = !this.$refs.attrs.validate();
            if (this.errorRequired) {
                return;
            }

            const payload = {
                name: this.name,
                attrs: this.$refs.attrs.payload(),
            };

            this.createScope({
                appID: this.appID,
                typeID: this.typeID,
                payload,
            }).then(() => {
                this.$router.push({ name: 'app-card', params: { appID: this.appID } });
            }).catch((err) => {
                this.errorMsg = err.message;
                this.errorRequired = false;
            });
        },
    },
};
