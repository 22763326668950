<template>
    <div>
        <div v-if="isLoading" />
        <div v-else-if="!isEmpty" class="col-12">
            <div class="h4 text-center">Подразделы</div>

            <scopes-filters :available="scopesAvailable" :filters="scopesFilters"
                @set-type="setFilterType($event)"
                @set-attr="setFilterAttr($event)"
                @set-value="setFilterValue($event)"
                @set-search="setFilterSearch($event)"
                @set-order="setFilterOrder($event)"
                />

            <div class="text-left">
                <div :key="item.id" v-for="item in scopesList">
                    <router-link custom v-slot="{ navigate }"
                        :to="{ name: routeName, params: { appID: item.id }}" >
                        <div @click="navigate" >
                            <app-scope-ref class="c-hand" :scope="item" />
                        </div>
                    </router-link>
                </div>
                <table-pagination
                    :page="scopesPage.page" :last="scopesPage.count"
                    @changed="changePage($event)"/>
            </div>
            <div class="columns p-2">
                <table-page-size class="col-ml-auto" @changed="changeSize($event)"
                    :size="scopesPage.size" :total="scopesPage.total"
                >разделов</table-page-size>
            </div>

            <error-msg :error="error" />
            <error-msg :error="scopesError" />
        </div>
    </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import * as A from '@/store/scope/scopes/actions';

import ErrorMsg from '@/components/common/ErrorMsg';
import TablePageSize from '@/components/common/TablePageSize';
import TablePagination from '@/components/common/TablePagination';

import AppScopeRef from '@/components/scope/AppScopeRef';

import ScopesFilters from './ScopesFilters';

export default {
    name: 'AppSubScopesList',
    props: ['appID', 'routeName'],
    components: {
        AppScopeRef, TablePageSize, TablePagination, ErrorMsg, ScopesFilters,
    },

    data() {
        return {
            isLoading: false,
            error: null,
        };
    },
    created() {
        this.reloadList();
    },

    watch: {
        appID: 'reloadList',
    },

    computed: {
        ...mapState('scope/scopes', {
            scopesList: 'list',
            scopesPage: 'pages',
            scopesFilters: 'filters',
            scopesAvailable: 'available',
            scopesError: 'error',
        }),
        isEmpty() {
            if (this.scopesFilters) {
                if (this.scopesFilters.type
                    || this.scopesFilters.attr
                    || this.scopesFilters.search) {
                    return false;
                }
            }
            return this.scopesList == null || this.scopesList.length === 0;
        },
    },

    methods: {
        ...mapActions('scope/scopes', {
            fetchScopesList: A.UPDATE_LIST,
            changePage: A.CHANGE_PAGE,
            changeSize: A.CHANGE_SIZE,
            setFilterType: A.FILTER_TYPE,
            setFilterAttr: A.FILTER_ATTR,
            setFilterValue: A.FILTER_VALUE,
            setFilterSearch: A.FILTER_SEARCH,
            setFilterOrder: A.FILTER_ORDER,
        }),

        reloadList() {
            this.isLoading = true;
            this.fetchScopesList({ appID: this.appID }).then(() => {
                this.error = null;
                this.isLoading = false;
            }).catch((err) => {
                this.error = err;
                this.isLoading = false;
            });
        },
    },
};
</script>
