import { mapActions } from 'vuex';

import DatePicker from 'vue2-datepicker';

import { START_NEW_TASK } from '@/store/tasks/actions';

import Api from '@/api';

export default {
    name: 'NewProcess',
    props: ['appID'],
    components: { DatePicker },

    data() {
        return {
            refs: [],
            selected: null,
            action: null,
            expireDate: null,
            name: '',
            errorMsg: null,
        };
    },

    computed: {
        canStart() { return this.selected != null && this.action != null && this.name.length > 3; },
    },

    watch: {
        appID() {
            this.update();
        },
        selected(v) {
            [this.action] = v.actions;
            this.expireDate = new Date(Date.now() + v.duration * 1000);
            this.expireDate.setMinutes(Math.round(this.expireDate.getMinutes() / 30) * 30);
            this.expireDate.setSeconds(0);
        },
    },

    created() {
        this.update();
    },

    methods: {
        ...mapActions('tasks', { startProcess: START_NEW_TASK }),

        update() {
            Api.listTaskRefs(this.appID).then((resp) => {
                this.refs = resp.data;
                this.selected = null;
                this.action = null;
                this.expireDate = null;
                this.name = '';
                this.errorMsg = null;
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },

        submitCreateProcess() {
            this.startProcess({
                scopeID: this.appID,
                stateID: this.action.id,
                payload: {
                    name: this.name,
                    expire: this.expireDate.getTime(),
                },
            }).then((task) => {
                this.$router.push({ name: 'task-info', params: { taskID: task.id } });
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },
    },
};
