import { mapGetters, mapState, mapActions } from 'vuex';

import { LOAD_SCOPE } from '@/store/scope/actions';

import AppLoading from '@/components/common/AppLoading';
import FileInfo from '@/components/common/FileInfo';
import SearchBox from '@/components/common/SearchBox';
import Api from '@/api';

import AppScopeRef from './AppScopeRef';
import AppAttrList from './AppAttrList';
import AppSubScopes from './scopes/AppSubScopes';

export default {
    name: 'AppCard',
    props: ['appID'],

    components: {
        AppLoading,
        AppScopeRef,
        AppAttrList,
        FileInfo,
        SearchBox,
        AppSubScopes,
    },

    data() {
        return {
            isLoading: true,
            searchText: null,
            filterText: null,
        };
    },

    computed: {
        ...mapState('scope', ['scope']),
        ...mapGetters('profile', ['scopeRoles']),
        hasFiles() { return this.scope.files.length > 0; },
        reportXlsxURL() { return Api.scopeProcessesReportURL(this.scope.id); },
    },

    watch: {
        appID() { this.reload(); },
    },

    created() {
        this.reload();
    },

    methods: {
        ...mapActions('scope', {
            loadScope: LOAD_SCOPE,
        }),

        reload() {
            this.isLoading = true;
            this.loadScope(this.appID).then(() => {
                this.isLoading = false;
            }).catch(() => {
                this.$router.push({ name: 'tasks' });
            });
        },

        runAction(code) {
            Api.runScopeAction(this.appID, code);
        },
    },
};
