<template>
    <tr>
        <td>{{row.name}}</td>
        <td v-for="column in columns" :key="column.id">
            {{row.values[column.id] && row.values[column.id].text}}
        </td>
    </tr>
</template>

<script>
export default {
    name: 'RowLine',
    props: ['row', 'columns'],
};
</script>
